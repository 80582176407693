import React, { useState, RefObject, useEffect, useRef } from "react";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";
import markdown from "highlight.js/lib/languages/markdown";
import { Mention, MentionsInput } from "react-mentions";

import { useStateValue } from "../Store";
import { Ting } from "../models";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { getTing, searchTings } from "../graphql/queries";
import { createTing } from "../graphql/mutations";
import { newTingDummy } from "../utils/dummy";
import { AWS_BUG_FIX } from "../constants";
import { v4 as uuid } from "uuid";
import Analytics from "@aws-amplify/analytics";
import mixpanel from "mixpanel-browser";

hljs.registerLanguage("markdown", markdown);

interface IProps {
  ting: Ting;
  setTing: Function;
  reference: string;
  editing?: boolean;
  referenceArray: Array<any>;
  setReferenceArray: Function;
  saveTing: Function;
  isEmpty: Function;
}

export const Editor = (props: IProps) => {
  //@ts-ignore
  // eslint-disable-next-line
  const [state, dispatch] = useStateValue();
  const [addTing] = useMutation(gql(createTing));
  const [emailArray, setEmailArray] = useState<Array<string>>([]);
  const htmlElRef = useRef<HTMLElement>();
  const search = useQuery(gql(searchTings), { skip: true });
  // const list = useQuery(gql(listTings), { skip: true });
  const getQuery = useQuery(gql(getTing), { skip: true });

  const shouldItSave = (e: any) => {
    // enter button pressed
    if (e.keyCode === 13) {
      // if it's while holding shift or on a mobile device, don't do anything.
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        e.shiftKey
      ) {
        return false;
      }
      // otherwise, save the note.
      else {
        e.preventDefault();
        props.isEmpty(true);
      }
    }
    // also save if you press escape
    else if (e.key === "Escape") {
      e.preventDefault();
      props.isEmpty(true);
    } else {
      return false;
    }
  };

  const onAddEmail = () => (id: React.ReactText, display: string) => {
    let oldEmail = emailArray.filter((mail) => mail === String(id));
    setEmailArray([...oldEmail, id as string]);
  };

  const mentionChange = (
    e: { target: { value: string } },
    value: any,
    plainTextValue: string,
    mentions: any
  ) => {
    props.setTing({ ...props.ting, content: value });
  };

  const onAddReference = (id: React.ReactText, display: string) => {
    // store the reference id as a string
    let refID: string = id as string;

    // check if the reference already exists
    getQuery
      .refetch({
        id: refID,
      })
      .then(async (data: any) => {
        // if the reference does not exist, create it
        if (data.data.getTing === null) {
          let dummy = newTingDummy(AWS_BUG_FIX);
          dummy.id = refID;
          dummy.content = display;
          dummy.references = [AWS_BUG_FIX];
          await addTing({
            variables: {
              input: { ...dummy },
            },
            update: (cache, { data: { ting } }) => {
              try {
                mixpanel.track("add reference created ting");
                Analytics.record("add-reference-created-ting");
                //@ts-ignore
                splitbee.track("add reference created ting");
              } catch (error) {
                console.warn(error);
              }
            },
          });
        } else {
          try {
            //@ts-ignore
            splitbee.track("add reference");
            mixpanel.track("add reference");
            Analytics.record("add-reference");
          } catch (error) {
            console.warn(error);
          }
        }
        // add the reference
        props.setReferenceArray([...props.referenceArray, refID]);
      })
      .catch((err) => console.warn(err));
  };

  function findUserByEmail(query: string) {
    // TODO: get friends or team-members and return here
    return [{ id: query, display: query }];
  }

  useEffect(() => {
    htmlElRef.current && htmlElRef.current.focus();
  }, [props.editing]);

  const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;
  return (
    <div className="ting-editor-container container mx-auto">
      <div className="w-full editorWrapper text-base md:text-lg">
        <MentionsInput
          value={props.ting.content}
          allowSpaceInQuery={true}
          allowSuggestionsAboveCursor={true}
          tabIndex={1}
          onKeyDown={(e) => shouldItSave(e)}
          onChange={mentionChange}
          className="editor-input"
          inputMode="search"
          style={{
            control: {
              fontWeight: "normal",
            },

            highlighter: {
              overflow: "hidden",
            },

            input: {
              margin: 0,
            },

            "&multiLine": {
              control: {},

              highlighter: {},

              input: {
                padding: "0",
                minHeight: "1rem",
                lineHeight: 1.625,
                outline: 0,
                fontSize: "inherit",
              },
            },

            suggestions: {
              list: {
                backgroundColor: "white",
                border: "1px solid rgba(0,0,0,0.15)",
                fontSize: 16,
              },

              item: {
                padding: "5px 15px",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                "&focused": {
                  backgroundColor: "#edf2f7",
                  fontWeight: 600,
                },
              },
            },
          }}
          placeholder="Add references by typing [["
          autoFocus
          inputRef={htmlElRef as RefObject<HTMLTextAreaElement>}
        >
          <Mention
            markup="[__display__](__id__)"
            displayTransform={(id, display) => `[${display}](${id})`}
            trigger="[["
            data={(query: string, callback: Function) => {
              const pattern = /([^\r\n\s#*--[\]][^!?,.\r\n]+[\w!?.,]+)/gm;
              let regxRes = pattern.exec(query);
              let queryString = regxRes ? regxRes[0] : query;
              if (queryString.length > 2) {
                search
                  .refetch({
                    filter: {
                      content: { matchPhrasePrefix: `${queryString}` },
                    },
                  })
                  .then((data) => {
                    if (!!data.data) {
                      callback([
                        ...data.data.searchTings.items
                          .slice(0, 5)
                          .map((t: Ting) => {
                            let shortString: string = t.content;
                            // REGEXP the first part of a string
                            // eslint-disable-next-line
                            const pattern = /([^\r\n\s#*--[\]][^!?,.\r\n]+[\w!?.,]+)/gm;
                            let regxRes = pattern.exec(shortString);
                            let result = regxRes
                              ? regxRes[0]
                              : shortString
                                  .substring(0, 20)
                                  .replace(/\n/g, " ");
                            return {
                              // display: shortString.substring(0, 20).replace(/\n/g, " "),
                              display: result,
                              id: t.id,
                            };
                          }),
                        {
                          id: uuid(),
                          display: `${query}`,
                        },
                      ]);
                    }
                  });
              }
            }}
            onAdd={onAddReference}
          />
          <Mention
            markup="[__display__](email:__id__)"
            trigger={emailRegex}
            data={(query: string) => findUserByEmail(query)}
            onAdd={onAddEmail}
            style={{ backgroundColor: "#d1c4e9" }}
          />
          {/* <Mention
            trigger="@"
            displayTransform={(username) => `@${username}`}
            markup="@__id__"
            data={(query: string, callback: Function) =>
              callback([
                {
                  id: uuid(),
                  display: `${query}`,
                },
              ])
            }
            regex={/@(\S+)/}
            style={{ backgroundColor: "#d1c4e9" }}
            appendSpaceOnAdd
          /> */}
          {/* <Mention
            trigger="@"
            displayTransform={(id, display) => `@${display}`}
            markup="@__display__"
            data={(query: string, callback: Function) =>
              callback([
                {
                  id: uuid(),
                  display: `${query}`,
                },
              ])
            }
            style={{ backgroundColor: "#d1c4e9" }}
          /> */}
        </MentionsInput>
      </div>
    </div>
  );
};

Editor.defaultProps = {
  ting: {
    id: uuid(),
    content: "",
    references: ["aws-bug-fix"],
  },
  editing: true,
  reference: "aws-bug-fix",
  handleClickOutside: () => false,
};

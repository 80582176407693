import React from "react";
import { Link } from "react-router-dom";

import { useStateValue } from "../Store";
import { TingSearch } from "./TingSearch";

export const LoggedInHeader = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [state, dispatch] = useStateValue();

  return (
    <div className="header-wrapper box-border bg-white bg-opacity-75 header z-40 sticky top-0">
      <ul className="flex justify-between pb-5 items-center py-4 px-4 md:px-0">
        <li className="flex-initial font-black text-green-500 md:pr-4 pr-2">
          <Link to="/" style={{ textDecoration: "none" }}>
            TING
          </Link>
        </li>

        <li className="flex-1 md:max-w-2xl">
          <TingSearch />
        </li>
        <li className="flex-initial">
          <a
            href="mailto:jesper@bettereachday.co?subject=I have some feedback about Ting"
            className="grid grid-flow-col items-center text-gray-600 hover:text-gray-900 border-2 border-gray-300 hover:border-gray-500 rounded-full py-2 md:py-1 px-2 md:px-3 text-sm ease-in duration-200"
          >
            <span className="mr-2 hidden md:block">Help & Feedback</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="stroke-current"
              viewBox="0 0 24 24"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
              <line x1="12" y1="11" x2="12" y2="11.01" />
              <line x1="8" y1="11" x2="8" y2="11.01" />
              <line x1="16" y1="11" x2="16" y2="11.01" />
            </svg>
          </a>
        </li>
        {/* <li
          className="md:pl-4 pl-2 cursor-pointer"
          onClick={() => dispatch({ type: "TOGGLE_SIDEBAR" })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="4" y1="8" x2="20" y2="8" />
            <line x1="4" y1="16" x2="20" y2="16" />
          </svg>
        </li> */}
      </ul>
    </div>
  );
};

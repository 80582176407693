import React, { useState, useEffect, useRef, RefObject } from "react";
import { Link, useHistory } from "react-router-dom";
import { searchTings } from "../graphql/queries";
import { useStateValue } from "../Store";
import { Ting } from "../models";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import OutsideClickHandler from "react-outside-click-handler";

export const TingSearch = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  const history = useHistory();
  // eslint-disable-next-line
  const { loading, refetch } = useQuery(gql(searchTings), { skip: true });
  const htmlElRef = useRef<HTMLInputElement>();
  const [queryStr, setQueryStr] = useState<string>("");
  const [results, setResults] = useState<Array<Ting>>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [showResults, setShowResults] = useState(false);
  const [q, setQ] = useState<string>("");
  const RESULT_LENGTH = 100;

  const search = async (q: string) => {
    setQ(q);
    if (q.length < 3) {
      setShowResults(false);
      setResults([]);
    } else {
      setLoad(true);
      await refetch({
        filter: {
          content: { matchPhrasePrefix: `${q}` },
        },
      }).then((res) => {
        if (!!res.data) {
          setShowResults(true);
          setResults([...res.data.searchTings.items]);
          setLoad(false);
        }
      });
      history.push(`/?s=${q}`);
    }
  };

  useEffect(() => {
    search(queryStr);
    // eslint-disable-next-line
  }, [queryStr]);

  useEffect(() => {
    if (store.selected === 0) {
      htmlElRef.current && htmlElRef.current.focus();
    }
  }, [store.selected]);

  return (
    <div className="relative z-30">
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowResults(false);
        }}
      >
        <input
          type="search"
          placeholder={`Search`}
          id="header--search"
          value={queryStr}
          onChange={(e) => setQueryStr(e.target.value)}
          ref={htmlElRef as RefObject<HTMLInputElement>}
          className="focus:bg-white font-light border-2 border-transparent bg-transparent outline-none text-base rounded-full leading-8 min-w-full pl-10 pr-4"
        />
        {(!!loading || !!load) && (
          <div className="absolute top-0 left-0 pt-2 leading-8 pl-3 text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fa-spin"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />
              <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" />
            </svg>
          </div>
        )}
        {!loading && showResults && results.length > 0 && (
          <ul className="absolute bg-gray-100 top-0 left-0 right-0 shadow-lg mx-4 md:mx-6 mt-12 rounded-lg z-40 border border-gray-200 overflow-hidden">
            {results.slice(0, 4).map((r: Ting) => {
              let sStart = 0;
              let qStart = r.content.toLowerCase().indexOf(q.toLowerCase());
              let qEnd = qStart + q.length;
              let sEnd = r.content.length;
              let contentArray = [];
              if (r.content.length > RESULT_LENGTH) {
                let length = RESULT_LENGTH - q.length;
                let halfLength = length / 2;
                sStart = qStart - halfLength;
                sEnd = qEnd + halfLength;
                if (sStart < 1) {
                  sEnd = sEnd + sStart * -1;
                  sStart = 0;
                }
              }
              contentArray.push(
                r.content.slice(sStart, qStart),
                r.content.slice(qStart, qEnd),
                r.content.slice(qEnd, sEnd)
              );
              return (
                <Link
                  to={`/${r.id}`}
                  className="border-b border-gray-200 py-2 px-4 hover:bg-gray-200 block text-black"
                  style={{ color: "#222" }}
                  onClick={(e) => {
                    setQueryStr("");
                    setShowResults(false);
                  }}
                  key={"search-result-" + r.id}
                >
                  {contentArray.map((s: string, i: number) =>
                    i === 1 ? (
                      <mark key={"marked" + r.id + i} className="font-bold">
                        {s}
                      </mark>
                    ) : (
                      s
                    )
                  )}
                  ...
                </Link>
              );
            })}
            <li>
              {results.length > 0 ? (
                <Link
                  onClick={(e) => {
                    setQueryStr("");
                    setShowResults(false);
                  }}
                  to={`/search/${encodeURIComponent(queryStr)}`}
                  className="border-b border-gray-200 text-sm text-green-600 py-2 px-4 hover:bg-gray-200 block text-black"
                >
                  Show all results
                </Link>
              ) : (
                <span className="border-b border-gray-200 py-2 px-4 hover:bg-gray-200 block text-black">
                  No results found.
                </span>
              )}
            </li>
          </ul>
        )}
      </OutsideClickHandler>
    </div>
  );
};

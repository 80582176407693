/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTing = /* GraphQL */ `
  query GetTing($id: ID!) {
    getTing(id: $id) {
      id
      createdAt
      sortedTo
      movedTo
      references
      referencedBy
      content
      updatedAt
      owner
    }
  }
`;
export const listTings = /* GraphQL */ `
  query ListTings(
    $filter: ModelTingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        sortedTo
        movedTo
        references
        referencedBy
        content
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchTings = /* GraphQL */ `
  query SearchTings(
    $filter: SearchableTingFilterInput
    $sort: SearchableTingSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchTings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        sortedTo
        movedTo
        references
        referencedBy
        content
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

import React from "react";
import { ItemLayout } from "./ItemLayout";
import Skeleton from "react-loading-skeleton";

export const LoadingFeed = () => (
  <ItemLayout>
    <Skeleton height={20} className="mb-10" />
    <Skeleton count={1} />
    <Skeleton count={1} className="mb-10" />
    <Skeleton count={2} />
    <Skeleton count={1} className="mb-10" />
    <Skeleton count={1} />
    <Skeleton count={1} className="mb-10" />
  </ItemLayout>
);

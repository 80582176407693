import React from "react";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { Query } from "react-apollo";
import { ApolloError } from "apollo-client";

import { getTing, listTings } from "../graphql/queries";
import { EditableItemLayout } from "../components/EditableItemLayout";
import { ItemLayout } from "../components/ItemLayout";
import { TingAction } from "../components/TingAction";
import { useStateValue } from "../Store";
import { useQueryVars } from "../hooks/useQueryVars";
import { Feed } from "../components/Feed";
import { LoadingFeed } from "../components/LoadingFeed";
import { HorizontalMenu } from "../components/HorizontalMenu";
import { TingContent } from "../components/TingContent";

export const TopicContainer = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  const { id } = useParams();

  const queryVars = useQueryVars(id);
  return (
    <div className="md:max-w-2xl md:mx-auto relative z-10">
      <HorizontalMenu />
      <Query query={gql(getTing)} variables={{ limit: 10000, id: id }}>
        {({
          loading,
          error,
          data,
          refetch,
        }: {
          loading: boolean;
          error?: ApolloError | undefined;
          data: any;
          refetch: any;
        }) => {
          if (loading) {
            return <LoadingFeed />;
          }

          if (error) {
            return <ItemLayout>`Error! ${error.message}`</ItemLayout>;
          }

          const topicTing = data.getTing ? data.getTing : undefined;

          return (
            <Query query={gql(listTings)} variables={queryVars}>
              {({
                loading,
                error,
                data,
              }: {
                loading: boolean;
                error?: ApolloError | undefined;
                data: any;
              }) => {
                if (loading) {
                  return <LoadingFeed />;
                }

                if (error) {
                  return <ItemLayout>`Error! ${error.message}`</ItemLayout>;
                }

                if (topicTing === undefined) {
                  refetch();
                  return <LoadingFeed />;
                }

                return (
                  <div className="pt-5 pb-10">
                    <EditableItemLayout>
                      <TingContent ting={topicTing} index={topicTing.id} />
                    </EditableItemLayout>
                    <Feed id={topicTing.id} tings={data.listTings.items} />
                    {!store.selected && (
                      <TingAction
                        referenceID={topicTing.id}
                        reference={topicTing}
                      />
                    )}
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </div>
  );
};

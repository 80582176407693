import React from "react";
import { useParams } from "react-router-dom";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloError } from "apollo-client";
import isSameDay from "date-fns/isSameDay";

import { useStateValue } from "../Store";

import { Ting } from "../models";
import { listTings } from "../graphql/queries";
import { ItemLayout } from "../components/ItemLayout";
import { Feed } from "../components/Feed";
import { TingAction } from "../components/TingAction";
import { useQueryVars } from "../hooks/useQueryVars";
import { Loading } from "../components/Loading";
import { LoadingFeed } from "../components/LoadingFeed";
import { HorizontalMenu } from "../components/HorizontalMenu";
import { useDate } from "../hooks/useDate";

export const DateContainer = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  const { id } = useParams();

  const queryVars = useQueryVars(id);
  const dateById = useDate(id);

  if (!id) return <Loading />;

  return (
    <div className="md:max-w-2xl md:mx-auto relative z-10">
      <HorizontalMenu />
      <Query query={gql(listTings)} variables={queryVars}>
        {({
          loading,
          error,
          data,
        }: {
          loading: boolean;
          error?: ApolloError | undefined;
          data: any;
        }) => {
          if (loading) return <LoadingFeed />;
          if (error) return <ItemLayout>`Error! ${error.message}`</ItemLayout>;
          return (
            <div className="pt-5 pb-10">
              <ItemLayout className="ting-header">
                {format(dateById, "cccc do LLL")}
              </ItemLayout>
              <Feed
                id={undefined}
                tings={data.listTings.items.filter(
                  (i: Ting) =>
                    !i.movedTo || isSameDay(new Date(i.movedTo), dateById)
                )}
              />
              {!store.selected && (
                <TingAction referenceID={undefined} reference={undefined} />
              )}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

import React from "react";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { Query } from "react-apollo";
import { ApolloError } from "apollo-client";

import { searchTings } from "../graphql/queries";
import { ItemLayout } from "../components/ItemLayout";
import { useStateValue } from "../Store";
import { Feed } from "../components/Feed";
import { LoadingFeed } from "../components/LoadingFeed";
import { HorizontalMenu } from "../components/HorizontalMenu";

export const SearchContainer = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  const { query } = useParams();

  return (
    <div className="md:max-w-2xl md:mx-auto relative z-10">
      <HorizontalMenu />
      <ItemLayout className="ting-header">
        Results for: {decodeURIComponent(query)}
      </ItemLayout>
      <Query
        query={gql(searchTings)}
        variables={{
          filter: {
            content: { matchPhrasePrefix: `${decodeURIComponent(query)}` },
          },
        }}
      >
        {({
          loading,
          error,
          data,
        }: {
          loading: boolean;
          error?: ApolloError | undefined;
          data: any;
        }) => {
          if (loading) {
            return <LoadingFeed />;
          }

          if (error) {
            return <ItemLayout>`Error! ${error.message}`</ItemLayout>;
          }

          return (
            <div className="pt-5 pb-10">
              <Feed id="" tings={data.searchTings.items} />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

import React from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { format, startOfToday, startOfYesterday, isValid } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDate } from "../hooks/useDate";

export const HorizontalMenu = () => {
  const { id } = useParams();
  const history = useHistory();
  const dateByID = useDate(id);
  const dateByNow = useDate(Date.now());

  return (
    <div className="flex px-4 bprder-2 text-gray-600 font-light">
      <NavLink
        className="mr-2"
        activeClassName="text-gray-800 border-b-2 border-gray-800 font-bold"
        to={`/${format(startOfToday(), "yyyy-MM-dd")}`}
      >
        Today
      </NavLink>
      <NavLink
        className="mr-2"
        activeClassName="text-gray-800 border-b-2 border-gray-800 font-bold"
        to={`/${format(startOfYesterday(), "yyyy-MM-dd")}`}
      >
        Yesterday
      </NavLink>
      <DatePicker
        dateFormat="Pp"
        onChange={(newDate: Date) =>
          //@ts-ignore
          history.push(`/${format(newDate, "yyy-MM-dd")}`)
        }
        selected={!!id && isValid(dateByID) ? dateByID : dateByNow}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "-37px, 0px",
          },
        }}
        customInput={
          <div className="flex cursor-pointer items-center ml-1">
            <span className="mr-1">Date</span>
            <span className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <rect x="4" y="5" width="16" height="16" rx="2" />
                <line x1="16" y1="3" x2="16" y2="7" />
                <line x1="8" y1="3" x2="8" y2="7" />
                <line x1="4" y1="11" x2="20" y2="11" />
                <rect x="8" y="15" width="2" height="2" />
              </svg>
            </span>
          </div>
        }
      />
      <div className="flex-1" />
      {/* <NavLink
        className="mr-2"
        activeClassName="text-gray-800 border-b-2 border-gray-800 font-bold"
        to="/all"
      >
        All notes
      </NavLink> */}
    </div>
  );
};

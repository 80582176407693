import React from "react";
import Skeleton from "react-loading-skeleton";

export const Loading = () => (
  <div className="md:max-w-2xl md:mx-auto relative z-10">
    <Skeleton className="mb-10" />
    <Skeleton count={2} />
    <Skeleton count={1} className="mb-4" />
    <Skeleton count={2} />
  </div>
);

import React from "react";
import "./App.css";
import awsconfig from "./aws-exports";
// import Amplify from "@aws-amplify/core";
// import Analytics from "@aws-amplify/analytics";
// import Auth from "@aws-amplify/auth";
import { Amplify, Auth, Analytics } from 'aws-amplify';
import mixpanel from "mixpanel-browser";

import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createAppSyncLink } from "aws-appsync";

import { StateProvider } from "./Store";
import { Ting } from "./models";
import { RouterWrapper } from "./RouterWrapper";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";

Amplify.configure({
  ...awsconfig,
});
Auth.configure({ ...awsconfig, mandatorySignIn: false });
// Analytics.configure(aws_mobile_analytics_app_id);
mixpanel.init("7fbe740101df5a327c24ef8182cb0534");
Analytics.autoTrack("pageView", {
  enable: true,
  type: "SPA",
});

// Amplify.Logger.LOG_LEVEL = "DEBUG";

const httpLink = createHttpLink({
  uri: awsconfig.aws_appsync_graphqlEndpoint,
});

const awsLink = createAppSyncLink({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType as "AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

const client = new ApolloClient({
  link: awsLink.concat(httpLink),
  cache: new InMemoryCache(),
  // resolvers: {
  //   Mutation: {
  //     CreateTing: (_, args, { cache }) => {
  //       // ... calculate and return the number of messages in
  //       // the cache ...
  //       console.log("createTing resolver", _, args, cache);
  //       // return {
  //       //   total: 123,
  //       //   __typename: "MessageCount",
  //       // };
  //     },
  //   },
  // },
});

interface IProps { }

const initialState = {
  selected: null,
  open: null,
  auth: {
    loading: true,
    user: null,
  },
  menu: false,
  favorites: [],
  currentQuery: {
    variables: {
      limit: 10000,
    },
    ID: undefined,
  },
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SELECT":
      if (!action.id) {
        document.documentElement.classList.remove("note--open");
      } else {
        document.documentElement.classList.add("note--open");
      }

      return {
        ...state,
        selected: action.id,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        auth: {
          loading: false,
          user: action.user,
        },
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        auth: {
          loading: false,
          user: action.user,
        },
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        auth: {
          loading: false,
          user: action.user,
        },
      };

    case "FOCUS_SEARCH":
      return {
        ...state,
        selected: 0,
      };

    case "MOVE_SELECT_UP":
      return state;
    case "MOVE_SELECT_DOWN":
      return state;
    case "TOGGLE_SIDEBAR":
      return { ...state, menu: !state.menu };

    case "ADD_FAVORITE":
      return {
        ...state,
        favorites: [
          ...state.favorites.filter(
            (f: { id: string; content: string }) => f.id !== action.data.id
          ),
          action.data,
        ],
      };

    case "REMOVE_FAVORITE":
      return {
        ...state,
        favorites: [
          ...state.favorites.filter(
            (f: { id: string; content: string }) => f.id !== action.data.id
          ),
        ],
      };

    case "UPDATE_TING":
      return {
        ...state,
        tings: [
          ...state.tings.filter((t: Ting) => t.id !== action.data.id),
          action.data,
        ],
      };

    case "UPDATE_TINGS":
      return { ...state, tings: action.tings };

    default:
      return state;
  }
};

const App = (props: IProps) => {
  return (
    <ApolloProvider client={client}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <RouterWrapper />
      </StateProvider>
    </ApolloProvider>
  );
};

export default App;

import React from "react";

import { useStateValue } from "../Store";
import { Ting } from "../models";
import { useParams } from "react-router-dom";
import { useAddNewTing } from "../hooks/useAddNewTing";

export const TingAction = ({
  referenceID,
  reference,
}: {
  referenceID: string | undefined;
  reference: Ting | undefined;
}) => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  const { id } = useParams();
  const callAddNewTing = useAddNewTing(referenceID ? referenceID : id);

  return (
    <div className="fixed cursor-pointer bottom-0 right-0 z-40 p-5">
      <div
        className={
          "rounded-full w-16 h-16 shadow-md bg-green-500 border-2 border-green-600 text-2xl text-white flex justify-center items-center"
        }
        onClick={() => callAddNewTing()}
      >
        <div className="align-middle">+</div>
      </div>
    </div>
  );
};

import React, { useState, useRef, useEffect } from "react";
import { Ting } from "../models";
import DOMpurify from "dompurify";
import marked from "marked";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../Store";
import { TingEditing } from "./TingEditing";

marked.setOptions({
  gfm: true,
  breaks: true,
});

export const TingContent = ({
  ting,
  index = 0,
  header = false,
  topic = undefined,
  topicID = undefined,
}: {
  ting: Ting;
  index?: Number;
  header?: Boolean;
  topic?: Ting | undefined;
  topicID?: string | undefined;
}) => {
  //@ts-ignore
  const [store, dispatch] = useStateValue();
  const [editing] = useState<boolean>(
    ting && store.selected === ting.id ? true : false
  );
  const history = useHistory();

  const scrollRef = useRef(null);
  const scrollToRef = (ref: any) =>
    ref.current ? window.scrollTo(0, ref.current.offsetTop - 50) : false;

  useEffect(() => {
    if (typeof ting !== "undefined" && store.selected === ting.id) {
      scrollToRef(scrollRef);
    }
  }, [store, ting]);

  let currentClass = () => {
    if (store.selected === ting.id) {
      return "w-full ting-content pt-2 pb-4 mb-4 leading-relaxed tracking-normal text-sm md:text-base px-4 bg-white rounded-lg shadow-lg border-2 border-gray-200";
    } else if (!!topic) {
      if (topic.referencedBy.includes(ting.id) || topic.id === ting.id) {
        return "w-full ting-content bg-transparent leading-relaxed tracking-normal text-gray-900 md:text-lg pt-2 px-4 border-2 border-transparent transition-all";
      } else {
        return "w-full ting-content bg-transparent leading-snug tracking-normal opacity-50 hover:opacity-100 text-sm md:text-base pt-1 px-4 border-2 border-transparent transition-all relative truncate";
      }
      // return "w-full ting-content bg-transparent leading-relaxed tracking-normal md:text-lg pt-2 px-4 border-2 border-transparent transition-all relative";
    } else {
      return "w-full ting-content bg-transparent leading-relaxed tracking-normal md:text-lg pt-2 px-4 border-2 border-transparent transition-all";
    }
  };

  if (typeof ting === "undefined") {
    return (
      <article className="w-full italic pb-4 pt-2">Unable to find ting</article>
    );
  } else {
    const content =
      store.selected === ting.id ? (
        <TingEditing
          ting={ting}
          editing={editing}
          topicID={topicID}
          key={ting.id}
        />
      ) : (
        <div
          className="w-full min-h-sm"
          onClick={(e) => {
            //@ts-ignore
            let HREF = e.nativeEvent.srcElement.getAttribute("href");
            if ((HREF && e.metaKey) || (HREF && HREF.indexOf("http") > -1)) {
              return true;
            } else if (HREF) {
              //strip out initial slash
              if (HREF.substr(0, 1) === "/") {
                HREF = HREF.substr(1, HREF.length);
              }
              history.push(`/${HREF}`);
              e.preventDefault();
            } else {
              dispatch({ type: "SELECT", id: ting.id });
              scrollToRef(scrollRef);
            }
          }}
        >
          <div
            className="ting-container"
            dangerouslySetInnerHTML={{
              __html: DOMpurify.sanitize(marked(ting.content)),
            }}
          />
        </div>
      );

    return (
      <article>
        {store.selected === ting.id && store.open && (
          <div className="md:hidden fixed bg-modal top-0 left-0 right-0 bottom-0 z-20"></div>
        )}
        <div className={currentClass()} ref={scrollRef}>
          {content}
        </div>
      </article>
    );
  }
};

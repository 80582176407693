import { Ting } from "../models";
import compareDesc from "date-fns/compareDesc";
import compareAsc from "date-fns/compareAsc";

export const sortTingsByDate = (a: Ting, b: Ting) => {
  return compareDesc(
    new Date(a.createdAt as string),
    new Date(b.createdAt as string)
  );
};

export const sortTingsByTime = (a: Ting, b: Ting) => {
  return compareAsc(
    new Date(a.createdAt as string),
    new Date(b.createdAt as string)
  );
};

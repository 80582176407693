import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { useDate } from "./useDate";

export function useQueryVars(id: string | Date | undefined | "ALL"): object {
  let queryVars: object = { limit: 100000 };

  if (id === "ALL") {
    queryVars = {
      limit: 10000,
      nextToken: null,
    };
  } else if (
    id instanceof Date ||
    (typeof id === "string" && id.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/))
  ) {
    //eslint-disable-next-line
    const startDate = startOfDay(useDate(new Date(id).toString()));
    //eslint-disable-next-line
    const endDate = endOfDay(useDate(new Date(id).toString()));

    queryVars = {
      filter: {
        or: [
          {
            createdAt: {
              between: [
                new Date(startDate).toISOString(),
                new Date(endDate).toISOString(),
              ],
            },
          },
          {
            movedTo: {
              between: [
                new Date(startDate).toISOString(),
                new Date(endDate).toISOString(),
              ],
            },
          },
        ],
      },
      limit: 10000,
    };
  } else if (typeof id === "string") {
    queryVars = {
      limit: 10000,
      filter: { references: { contains: id } },
      nextToken: null,
    };
  } else if (typeof id !== "undefined") {
    queryVars = {
      limit: 10000,
      filter: { references: { contains: id } },
      nextToken: null,
    };
  }

  return queryVars;
}

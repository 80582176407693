import React from "react";
import { Ting } from "../models";
import { TingContent } from "./TingContent";
import { sortTingsByTime } from "../utils/sorters";
import { ItemLayout } from "./ItemLayout";
import { useAddNewTing } from "../hooks/useAddNewTing";

export const Feed = ({
  id,
  tings,
}: {
  id: string | undefined;
  tings: Array<Ting>;
}) => {
  const addNewTing = useAddNewTing({ referenceID: id, reference: undefined });
  let sortedTings = tings.sort(sortTingsByTime);
  if (!!id) {
    sortedTings = sortedTings.filter((t: Ting) => t.id !== id && !t._deleted);
  }

  if (sortedTings.length === 0) {
    return (
      <div className="ting-feed mb-10">
        <ItemLayout>
          <p className="text-xl text-gray-500 font-light md:text-center">
            No notes here yet, want to{" "}
            <span
              onClick={() => addNewTing()}
              className="text-green-500 cursor-pointer"
            >
              add one?
            </span>
          </p>
        </ItemLayout>
      </div>
    );
  } else {
    return (
      <div className="ting-feed mb-10">
        {sortedTings
          .filter((t: Ting) => t.id !== id)
          .map((t: Ting, i: number) => (
            <TingContent key={"content-" + t.id + i} ting={t} index={i} />
          ))}
      </div>
    );
  }
};

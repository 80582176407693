// import { Ting } from "../models";
// import { useStateValue } from "../Store";

export const addNewTing = async () => {
  //@ts-ignore
  // eslint-disable-next-line
  // const [store, dispatch] = useStateValue();
  // const AWS_BUG_FIX = "aws_bug_fix";
  // const newTing = {
  //   content: "test",
  //   references: [AWS_BUG_FIX],
  //   createdAt: new Date().toISOString()
  // };
  // const addedTing = await DataStore.save(new Ting({ ...newTing }));
  // if (addedTing) {
  //   dispatch({ type: "SELECT", id: addedTing.id });
  // } else {
  //   console.warn("Error adding new Ting: ", addedTing);
  // }
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTing = /* GraphQL */ `
  mutation CreateTing(
    $input: CreateTingInput!
    $condition: ModelTingConditionInput
  ) {
    createTing(input: $input, condition: $condition) {
      id
      createdAt
      sortedTo
      movedTo
      references
      referencedBy
      content
      updatedAt
      owner
    }
  }
`;
export const updateTing = /* GraphQL */ `
  mutation UpdateTing(
    $input: UpdateTingInput!
    $condition: ModelTingConditionInput
  ) {
    updateTing(input: $input, condition: $condition) {
      id
      createdAt
      sortedTo
      movedTo
      references
      referencedBy
      content
      updatedAt
      owner
    }
  }
`;
export const deleteTing = /* GraphQL */ `
  mutation DeleteTing(
    $input: DeleteTingInput!
    $condition: ModelTingConditionInput
  ) {
    deleteTing(input: $input, condition: $condition) {
      id
      createdAt
      sortedTo
      movedTo
      references
      referencedBy
      content
      updatedAt
      owner
    }
  }
`;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4263ce4b-94a3-46df-9834-099fb1b42e06",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_9HyuAJi04",
    "aws_user_pools_web_client_id": "1vf83edrrnvh2k2vq29sd5um61",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fmmlnlyd2bbe5h5mkli7mjxohq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "ting-20200309172715-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1gj4kncw55wtc.cloudfront.net",
    "aws_mobile_analytics_app_id": "e8960d2385324606b01aa4b0b983dfee",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;

import { Ting } from "../models";
import { v4 as uuid } from "uuid";
import { AWS_BUG_FIX } from "../constants";
import format from "date-fns/format";

export const newTingDummy = (id: string) => {
  return {
    id: uuid(),
    content: "",
    references: [id],
    referencedBy: [""],
    sortedTo: new Date().toISOString(),
    movedTo: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  } as Ting;
};

export const newDateTingDummy = (id: string) => {
  let startDate = new Date(id);
  startDate.setHours(0, 0, 0, 0);
  return {
    id: uuid(),
    content: format(new Date(id), "eeee do LLL"),
    references: [AWS_BUG_FIX],
    createdAt: startDate.toISOString(),
  } as Ting;
};

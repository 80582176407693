import React, { useState } from "react";
import Auth from "@aws-amplify/auth";
import { Switch, Route, useHistory } from "react-router-dom";

export const Signup = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  let history = useHistory();

  async function signUp() {
    if (username.length < 2 || password.length < 2) {
      return false;
    }
    setLoading(true);
    Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    })
      .then((data: any) => {
        setLoading(false);
        history.push("/confirmation");
      })
      .catch((err: any) => console.warn(err));
  }
  async function confirm() {
    // After retrieving the confirmation code from the user'
    setLoading(true);
    Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
    })
      .then((data: any) => {
        setLoading(false);
        history.push("/login");
      })
      .catch((err: any) => console.warn(err));
  }
  //   async function resendSignup() {
  //     Auth.resendSignUp(username)
  //       .then(() => {
  //         console.log("code resent successfully");
  //       })
  //       .catch((e: any) => {
  //         console.warn(e);
  //       });
  //   }

  return (
    <div>
      <Switch>
        <Route path={`/signup`}>
          <div className="mb-10">
            <h1 className="text-2xl">Sign up for the Ting closed alpha.</h1>
            <p className="mb-4">
              This is a first iteration of a personal, but atomically shareable,
              notebook. Private invite only. Built by{" "}
              <a target="_new" href="https://twitter.com/jesperbylund">
                @jesperbylund
              </a>
              .
            </p>
            <h2 className="text-md italic">What is Ting?</h2>
            <p className="text-sm">
              You can create infinite notes in Ting, and relate them to any
              amount of topics. Every time you link a Ting with another Ting
              both of them are updated with a link, which makes your notes
              organically structured in the way you think. No more time wasted
              organising notes, or relearning things you've noted before.
            </p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              signUp();
            }}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="user-email"
              >
                Email:
              </label>
              <input
                name="user-email"
                type="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="mail@server.com"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                tabIndex={-1}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="user-pass"
              >
                Password:
              </label>
              <input
                name="user-pass"
                type="password"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="********"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                tabIndex={1}
              />
            </div>
            <div className="mb-4">
              <input
                className={
                  loading
                    ? "bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    : "bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                }
                name="submit-btn"
                type="submit"
                disabled={loading}
                value={loading ? "Signing up..." : "Sign up"}
                tabIndex={2}
              />
            </div>
          </form>
        </Route>
        <Route path={"/confirmation"}>
          <h1 className="text-4xl">Confirm</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              confirm();
            }}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="user-email"
              >
                Email:
              </label>
              <input
                name="user-email"
                type="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="mail@server.com"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                tabIndex={-1}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="user-pass"
              >
                Verification code:
              </label>
              <input
                name="code"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="012345"
                value={code}
                required
                onChange={(e) => setCode(e.target.value)}
                tabIndex={1}
              />
            </div>
            <div className="mb-4">
              <input
                className={
                  loading
                    ? "bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    : "bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                }
                name="submit-btn"
                type="submit"
                disabled={loading}
                value={loading ? "Confirming..." : "Confirm"}
                tabIndex={2}
              />
            </div>
          </form>
        </Route>
      </Switch>
    </div>
  );
};

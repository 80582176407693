import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";

import { useStateValue } from "../Store";
import { useApolloClient } from "@apollo/react-hooks";

export const Sidebar = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [state, dispatch] = useStateValue();
  const apolloClient = useApolloClient();

  const logout = () => {
    Auth.signOut({ global: true })
      .then(async (data) => {
        await apolloClient.clearStore();
        dispatch({ type: "LOGOUT_SUCCESS", user: null });
      })
      .catch((err) => console.warn(err));
  };

  return (
    <div>
      {!!state.menu && (
        <div className="md:hidden fixed bg-modal top-0 left-0 right-0 bottom-0 z-20"></div>
      )}
      {!!state.menu && (
        <OutsideClickHandler
          onOutsideClick={() => {
            dispatch({ type: "TOGGLE_SIDEBAR" });
          }}
        >
          <div className="fixed right-0 bottom-0 top-0 bg-gray-300 z-50 w-1/2 px-4 py-6">
            {/* <div>
              <h2 className="font-semibold text-lg">Favorites</h2>
              <ul>
                {state.favorites.map((f: any) => (
                  <li>
                    <Link to={`/${f.id}`}>{f.content}</Link>
                  </li>
                ))}
              </ul>
            </div> */}
            <div>
              <h2 className="font-semibold text-lg">Menu</h2>
              <ul>
                <li>
                  <Link to="/" onClick={logout}>
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

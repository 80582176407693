import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { useStateValue } from "../Store";
import { createTing, updateTing } from "../graphql/mutations";
import { newTingDummy } from "../utils/dummy";
import { listTings } from "../graphql/queries";
import { Ting } from "../models";
import { useQueryVars } from "../hooks/useQueryVars";
import { useParams } from "react-router-dom";
import Analytics from "@aws-amplify/analytics";
import mixpanel from "mixpanel-browser";
import { useDate } from "./useDate";

export function useAddNewTing({
  referenceID,
  reference,
}: {
  referenceID: string | undefined;
  reference: Ting | undefined;
}): Function {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  const [addTing] = useMutation(gql(createTing));
  const [mutateTing] = useMutation(gql(updateTing));
  const { id } = useParams();
  const queryVars = useQueryVars(referenceID ? referenceID : id);

  const dateById = useDate(id);
  const dateByNow = useDate(Date.now());

  const addNewTing = async () => {
    let newTing = newTingDummy(referenceID ? referenceID : "");
    let theDateForThisTing = null;
    if (typeof id === "string" && id.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
      theDateForThisTing = dateById.toISOString();
    } else if (typeof id === "string") {
      theDateForThisTing = dateByNow.toISOString();
    }

    addTing({
      variables: {
        input: { ...newTing, movedTo: theDateForThisTing },
      },
      optimisticResponse: {
        __typename: "Mutation",
        createTing: {
          ...newTing,
          __typename: "Ting",
          _deleted: false,
          updatedAt: dateByNow.toISOString(),
          owner: store.auth.user.username,
          movedTo: theDateForThisTing,
        },
      },
      update: (cache, { data: { createTing } }) => {
        try {
          //@ts-ignore
          splitbee.track("created ting");
          mixpanel.track("created ting");
          Analytics.record("created-ting");
        } catch (error) {
          console.warn(error);
        }

        const data: any = cache.readQuery(
          {
            query: gql(listTings),
            variables: queryVars,
          },
          true
        );

        if (data) {
          data.listTings.items = [
            ...data.listTings.items.filter((i: Ting) => i.id !== createTing.id),
            createTing,
          ];

          // AUTO BACK-REFERENCING THE TOPIC
          if (
            typeof referenceID !== "undefined" &&
            typeof reference !== "undefined"
          ) {
            const newRefs = [
              ...reference.referencedBy.filter(
                (ref: string) =>
                  data.listTings.items.findIndex((i: Ting) => i.id === ref) + 1
              ),
            ];
            const newRefsBy = [...new Set([...newRefs, newTing.id])];
            mutateTing({
              variables: {
                input: {
                  id: reference.id,
                  referencedBy: newRefsBy,
                },
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateTing: {
                  id: reference.id,
                  __typename: "Ting",
                  content: reference.content,
                  references: reference.references,
                  createdAt: reference.createdAt,
                  sortedTo: reference.sortedTo,
                  movedTo: reference.movedTo,
                  referencedBy: newRefsBy,
                  owner: reference.owner,
                  updatedAt: reference.updatedAt,
                },
              },
            });
            try {
              mixpanel.track("auto reference ting");
              Analytics.record("auto-reference-ting");
            } catch (error) {
              console.warn(error);
            }
          }
          cache.writeQuery({
            query: gql(listTings),
            data: data,
            variables: queryVars,
          });
          dispatch({ type: "SELECT", id: newTing.id });
          return newTing;
        } else {
          console.warn("Error adding new Ting: ");
        }
      },
    });
  };

  return addNewTing;
}

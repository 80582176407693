import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStateValue } from "../Store";
import { Ting } from "../models";
import { Editor } from "./Editor";
import gql from "graphql-tag";
import {
  updateTing,
  deleteTing as deleteTingMutation,
} from "../graphql/mutations";
import { useMutation } from "react-apollo";
import { listTings } from "../graphql/queries";
import { isToday, format } from "date-fns";
import { useQueryVars } from "../hooks/useQueryVars";
import Analytics from "@aws-amplify/analytics";
import mixpanel from "mixpanel-browser";
import { useAddNewTing } from "../hooks/useAddNewTing";

interface Dic {
  [key: string]: string;
}

export const TingEditing = ({
  ting,
  editing,
  reference,
  topicID,
}: {
  ting: Ting;
  editing?: boolean;
  reference: string;
  topicID?: string;
}) => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();
  // eslint-disable-next-line
  const [newTing, setNewTing] = useState<Ting>(ting);
  const { id } = useParams();
  const queryVars = useQueryVars(topicID ? topicID : id);
  const [referenceArray, setReferenceArray] = useState<Array<string>>([
    reference,
  ]);
  const addNewTing = useAddNewTing(topicID ? topicID : id);

  const [mutateTing] = useMutation(gql(updateTing), {
    variables: {
      input: {
        id: newTing.id,
        content: newTing.content,
        movedTo: newTing.movedTo,
        sortedTo: newTing.sortedTo,
        references: [...new Set([...newTing.references, ...referenceArray])],
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateTing: {
        id: newTing.id,
        __typename: "Ting",
        content: newTing.content,
        references: newTing.references,
        createdAt: newTing.createdAt,
        sortedTo: newTing.sortedTo,
        movedTo: newTing.movedTo,
        referencedBy: newTing.referencedBy,
        owner: newTing.owner,
        updatedAt: newTing.updatedAt,
      },
    },
  });

  const [removeTing] = useMutation(gql(deleteTingMutation), {
    variables: {
      input: {
        id: newTing.id,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteTing: {
        __typename: "Ting",
        id: newTing.id,
        content: newTing.content,
        references: newTing.references,
        createdAt: newTing.createdAt,
        sortedTo: newTing.sortedTo,
        movedTo: newTing.movedTo,
        referencedBy: newTing.referencedBy,
        owner: newTing.owner,
        updatedAt: newTing.updatedAt,
      },
    },
    update(cache, response) {
      const data: any = cache.readQuery(
        {
          query: gql(listTings),
          variables: queryVars,
        },
        true
      );
      if (!!data && !!data.listTings) {
        cache.writeQuery({
          query: gql(listTings),
          data: {
            ...data,
            listTings: {
              ...data.listTings,
              items: [
                ...data.listTings.items.filter(
                  (t: Ting) => t.id !== newTing.id
                ),
              ],
            },
          },
          variables: queryVars,
        });
      }
    },
  });

  // Should be used for selected deletion
  // const deleteTing = async () => {
  //   const res = window.confirm(
  //     `Delete this ting? "${
  //       newTing.content.length > 2
  //         ? newTing.content.substr(0, 20) + "..."
  //         : newTing.id
  //     }"`
  //   );
  //   if (res) {
  //     removeTing();
  //   }
  // };

  async function updateTingDate(newDate: Date) {
    setNewTing({ ...newTing, movedTo: newDate.toISOString() });
    setTimeout(() => {
      saveTing();
    }, 100);
  }

  async function unselectTing(isDesktop?: boolean) {
    if (newTing.content.replace(/\s+/g, "").length === 0) {
      removeTing();
      dispatch({ type: "SELECT", id: null });
    } else {
      saveTing();
      isDesktop && addNewTing();
    }
  }

  async function saveTing() {
    // update the Ting
    mutateTing();
    try {
      //@ts-ignore
      splitbee.track("update ting");
      mixpanel.track("update ting");
      Analytics.record("update-ting");
    } catch (error) {
      console.warn(error);
    }
    // unselect the Ting
    dispatch({ type: "SELECT", id: null });
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        unselectTing();
      }}
    >
      <Editor
        ting={newTing}
        setTing={setNewTing}
        editing={editing}
        saveTing={saveTing}
        isEmpty={unselectTing}
        referenceArray={referenceArray}
        setReferenceArray={setReferenceArray}
      />
      <div className="flex flex-row mt-2 text-sm md:md items-center">
        <Link
          to={`/${ting.id}`}
          className="text-xs text-gray-600 flex items-center mr-2"
          style={{ color: "#718096" }}
        >
          <svg
            className="mr-1"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
            <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
          </svg>
          {isToday(new Date(ting.createdAt as string))
            ? format(new Date(ting.createdAt as string), "H:mm")
            : format(new Date(ting.createdAt as string), "LLL do, H:mm")}
        </Link>
        <div className="mx-2">
          {ting.createdAt && (
            <DatePicker
              onChange={(newDate: Date) => updateTingDate(newDate)}
              selected={
                newTing.createdAt
                  ? new Date(newTing.movedTo as string)
                  : new Date(newTing.createdAt as string)
              }
              customInput={
                <div className="cursor-pointer pt-1 px-2 flex items-center text-gray-600">
                  <svg
                    className="mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <rect x="4" y="5" width="16" height="16" rx="2" />
                    <line x1="16" y1="3" x2="16" y2="7" />
                    <line x1="8" y1="3" x2="8" y2="7" />
                    <line x1="4" y1="11" x2="20" y2="11" />
                    <rect x="8" y="15" width="2" height="2" />
                  </svg>
                  <div>Change date</div>
                </div>
              }
            />
          )}
        </div>
        {/* <div
          className="mx-2 text-xs text-gray-600 cursor-pointer flex items-center"
          onClick={() => {
            dispatch({
              type: "ADD_FAVORITE",
              data: {
                id: newTing.id,
                content: newTing.content.substr(0, 10),
              },
            });
          }}
        >
          <svg
            className="mr-1"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
            <line x1="9" y1="12" x2="15" y2="12" />
            <line x1="12" y1="9" x2="12" y2="15" />
          </svg>
          Add to menu
        </div> */}
        <div className="ml-auto">
          <div
            className="cursor-pointer flex items-center bold text-sm text-gray-600"
            onClick={() => unselectTing()}
          >
            Save{" "}
            <span className="instruction hidden md:block px-1 mx-1 rounded-md bg-gray-200 border border-gray-300 text-gray-700 text-xs">
              Enter
            </span>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

TingEditing.defaultProps = {
  editing: true,
  reference: "aws-bug-fix",
  handleClickOutside: () => false,
};

import React, { useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import format from "date-fns/format";
import startOfToday from "date-fns/startOfToday";
import startOfYesterday from "date-fns/startOfYesterday";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { HotKeys } from "react-hotkeys";

import { useStateValue } from "./Store";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { DateContainer } from "./pages/DateContainer";
import { LoggedInHeader } from "./components/LoggedInHeader";
import { Sidebar } from "./components/Sidebar";
import { addNewTing } from "./functions/NewTing";
import { TopicContainer } from "./pages/TopicContainer";
import { AllContainer } from "./pages/AllContainer";
import { SearchContainer } from "./pages/SearchContainer";
import Analytics from "@aws-amplify/analytics";
import mixpanel from "mixpanel-browser";
import { useDate } from "./hooks/useDate";

const UserContext = React.createContext({} as CognitoUser);
const Dummy = (props: any) => <h1 className="text-4xl">{props.name}</h1>;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const UnAuthedHeader = () => (
  <div className="box-border">
    <ul className="flex pb-5 items-center mb-4 py-4">
      <li className="font-black text-green-500 md:pr-4 pr-2">
        <a href="/" style={{ textDecoration: "none" }}>
          TING
        </a>
      </li>
      {/* <li className="flex-1 ml-auto">
    <Link to="/signup">Sign up</Link>
  </li> */}
      {/* <li className="md:pl-4 pl-2">
  <Link to="/signup">Help?</Link>
</li> */}
    </ul>
  </div>
);

const UnAuthedRouter = () => (
  <Router>
    <UnAuthedHeader />
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route path="/reset">
        <Dummy name="Reset" />
      </Route>
      <Route path="/confirmation">
        <Signup />
      </Route>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  </Router>
);

const AuthedRouter = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [store, dispatch] = useStateValue();

  const keyMap = {
    moveUp: ["up", "j"],
    moveDown: ["down", "k"],
    addNew: "space",
    search: ["f", "command+f", "control+f"],
    DELETE_NODE: ["del", "backspace"],
  };

  const handlers = {
    moveUp: (event: any) => dispatch({ type: "MOVE_SELECT_UP" }),
    moveDown: (event: any) => dispatch({ type: "MOVE_SELECT_DOWN" }),
    addNew: (event: any) => addNewTing(),
    search: (event: any) => dispatch({ type: "FOCUS_SEARCH" }),
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers} className="outline-none">
      <Router>
        <ScrollToTop />
        <LoggedInHeader />
        <Sidebar />

        <Switch>
          <Route exact path="/all">
            <AllContainer />
          </Route>
          <Route exact path="/search/:query">
            <SearchContainer />
          </Route>
          <Route exact path="/:id">
            <ViewSwitcher />
          </Route>
          <Route exact path="/user">
            <Dummy />
          </Route>
          <Route path="/settings">
            <Dummy name="Settings" />
          </Route>
          <Redirect
            from="/yesterday"
            to={`/${format(startOfYesterday(), "yyyy-MM-dd")}`}
          />
          <Redirect
            from="/today"
            to={`/${format(startOfToday(), "yyyy-MM-dd")}`}
          />
          <Redirect
            from="/"
            to={`/${format(useDate(Date.now()), "yyyy-MM-dd")}`}
          />
          <Redirect
            from="/signnup"
            to={`/${format(useDate(Date.now()), "yyyy-MM-dd")}`}
          />
          <Redirect
            from="/login"
            to={`/${format(useDate(Date.now()), "yyyy-MM-dd")}`}
          />

          <Route path="/team/:teamid">
            <Dummy />
          </Route>
          <Route path="/team/:teamid/:id">
            <Dummy />
          </Route>
          <Route path="/team/:teamid/all">
            <Dummy />
          </Route>
        </Switch>
      </Router>
    </HotKeys>
  );
};

const ViewSwitcher = () => {
  const { id } = useParams();
  // eslint-disable-next-line
  if (typeof id !== "undefined" && id.match(/[0-9]{4}\-[0-9]{2}\-[0-9]{2}/)) {
    return <DateContainer />;
  } else {
    return <TopicContainer />;
  }
};

export const RouterWrapper = () => {
  // @ts-ignore
  const [{ auth }, dispatch] = useStateValue();

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        // console.log(user);
        try {
          mixpanel.identify(user.username);
          // add these things to mixpanel!
          // "Sign up date": user.attributes.createdAt, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
          // credits: 150, // ...or numbers
          mixpanel.people.set({
            $email: user.attributes.email, // only reserved properties need the $
            USER_ID: user.username, // use human-readable names
            segment: "alpha",
          });
          //@ts-ignore
          // splitbee.user.set({ plan: "Alpha", appVersion: "1.0.0" });
          //@ts-ignore
          splitbee.user.set({
            displayName: user.attributes.email,
            id: user.username,
            email: user.attributes.email,
          });
          Analytics.updateEndpoint({
            address: user.attributes.email,
            userId: user.username,
          });
        } catch (error) {
          console.warn(error);
        }
        dispatch({ type: "LOGIN_SUCCESS", user: user });
      })
      .catch((err) => {
        console.warn(err);
        dispatch({ type: "LOGIN_ERROR", user: null });
      });
  }, [dispatch]);

  return (
    <div className="container mx-auto px-2 outline-none">
      {auth.loading && (
        <div>
          <UnAuthedHeader />
          <div className="text-gray-700 text-lg">Authenticating...</div>
        </div>
      )}
      {auth.user && !auth.loading && (
        <UserContext.Provider value={(auth.user as any) as CognitoUser}>
          <AuthedRouter />
        </UserContext.Provider>
      )}
      {!auth.user && !auth.loading && <UnAuthedRouter />}
    </div>
  );
};

import React, { useState } from "react";
import Auth from "@aws-amplify/auth";
import { useStateValue } from "../Store";

export const Login = () => {
  //@ts-ignore
  // eslint-disable-next-line
  const [state, dispatch] = useStateValue();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  async function SignIn() {
    if (username.length < 2 || password.length < 2) {
      return false;
    }
    try {
      setLoading(true);
      const user = await Auth.signIn(username, password);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setLoading(false);
        // // You need to get the code from the UI inputs
        // // and then trigger the following function with a button click
        // const code = getCodeFromUserInput();
        // // If MFA is enabled, sign-in should be confirmed with the confirmation code
        // const loggedUser = await Auth.confirmSignIn(
        //   user, // Return object from Auth.signIn()
        //   code, // Confirmation code
        //   mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        // );
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setLoading(false);
        // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        // // You need to get the new password and required attributes from the UI inputs
        // // and then trigger the following function with a button click
        // // For example, the email and phone_number are required attributes
        // const { username, email, phone_number } = getInfoFromUserInput();
        // const loggedUser = await Auth.completeNewPassword(
        //   user, // the Cognito User Object
        //   newPassword, // the new password
        //   // OPTIONAL, the required attributes
        //   {
        //     email,
        //     phone_number
        //   }
        // );
      } else if (user.challengeName === "MFA_SETUP") {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        Auth.setupTOTP(user);
      } else {
        // The user directly signs in
        dispatch({ type: "LOGIN_SUCCESS", user: user });
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <div className="mb-10">
        <h1 className="text-xl text-center">
          Welcome to the Ting closed alpha.
        </h1>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          SignIn();
        }}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="user-email"
          >
            Email:
          </label>
          <input
            name="user-email"
            type="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="mail@server.com"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            tabIndex={-1}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="user-pass"
          >
            Password:
          </label>
          <input
            name="user-pass"
            type="password"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="********"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
            tabIndex={1}
          />
        </div>
        <div className="mb-4">
          <input
            className={
              loading
                ? "bg-green-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                : "bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            }
            name="submit-btn"
            type="submit"
            disabled={loading}
            value={loading ? "Loggin in..." : "Log in"}
            tabIndex={2}
          />
        </div>
      </form>
    </div>
  );
};
